@import "./assets/fonts/Lato.css";

html,body{
  margin:0;
}

body {
  margin: 0;
  font-family: Lato, Helvetica, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Consolas, source-code-pro, Menlo, Monaco, "Ubuntu Mono", "source-code-pro", monospace;
}

* {
  box-sizing: border-box;
}